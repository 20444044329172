// import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

export function getList(data) {
    return dmshttp({
        // url: 'getDistributorSalesOrderList',
        // url: 'getSalesOrderDetailList',
        url: 'getDistributorSalesOrderDetailList',
        data
    })
}



export function batch(data) {
    return dmshttp({
        url: 'batchInvalidSalesOrder',
        data
    })
}

export function exportDate(data) {
    return dmshttp({
        url: 'exportDistributorSalesOrderDetailList' + '&type=1',
        data,
        responseType: 'blob'
    })
}