import {
    getList,
    batch,
    exportDate
} from './api';
import Util from "@/utils/utils";
export default {
    name: 'scaleOrderList',
    data() {
        return {
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页",
                },
                {
                    path: "/channelOrder/orderReview",
                    name: "orderreview",
                    title: "分销商零售订单",
                },
            ],
            navList: [{
                    title: "销售订单",
                    path: "/channelOrder/orderReview",
                    active: true
                },
                {
                    title: "分销直采订单",
                    path: "/channelOrder/distributorPurchase",
                    active: false
                },
                {
                    title: "销售进度查询",
                    path: "/channelOrder/salesProgress",
                    active: false
                }
            ],
            page: {
                pageNo: 1,
                pageSize: 10
            },
            goodsList: [],
            invalidList: [], //批量失效
            insearchOBJ: {
                endDate: '',
                documentType: '',
                documentNum: '',
                sellerName: '',
                returnStatus: '',
                purchaseNum: '',
                customerName: '',
                startDate: '',
                status: ''
            },
            paramList: [],
            searchzz: false,
            loadings: false,
            pageLoadFlag: true,
            goodsListlength: 0,
            paramListExport: [],
            tableData: [],
            showTotalColumns: ['销售数量', '销售金额', '出库数量', '出库金额'],
            tcolumns: [{
                    label: "商家编码",
                    prop: "dealerCode",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "商家名称",
                    prop: "dealerName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "客户编码",
                    prop: "customerCode",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "客户名称",
                    prop: "customerName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "门店编码",
                    prop: "storeCode",
                    ellipsis: true,
                    width: 200
                }, {
                    label: "门店名称",
                    prop: "storeName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "订单编号",
                    prop: "documentNum",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "订单状态",
                    prop: "statusName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "退货状态",
                    prop: "returnStatusName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "订单库存状态",
                    prop: "invStatusName",
                    ellipsis: true,
                    width: 200
                },
                // {
                //     label: "物料组编码",
                //     prop: "materialGroupCode",
                //     ellipsis: true,
                //     width: 200
                // },
                {
                    label: "物料组名称",
                    prop: "materialGroupName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "型号",
                    prop: "model",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "颜色",
                    prop: "colour",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "销售类型",
                    prop: "retailTypeName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "销售数量",
                    prop: "backnowledgedQty",
                    ellipsis: true,
                    width: 200,
                    align: 'right'
                },
                {
                    label: "销售金额",
                    prop: "acknowledgedAmount",
                    ellipsis: true,
                    width: 200,
                    align: 'right'
                },
                {
                    label: "出库日期",
                    prop: "outDate",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "出库仓库",
                    prop: "warehouseName",
                    ellipsis: true,
                    width: 200
                },
                {
                    label: "出库数量",
                    prop: "shippedBqty",
                    ellipsis: true,
                    width: 200,
                    align: 'right'
                },
                {
                    label: "出库金额",
                    prop: "outAmount",
                    ellipsis: true,
                    width: 200,
                    align: 'right'
                },
                {
                  label: "是否专卖店",
                  prop: "isSpeclalShop",
                  ellipsis: true,
                  width: 160,
                },
            ],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                count: 100
            },
            requestdata: {},
            isLoading: false
        }
    },
    beforeCreate() {
        // 添加背景色
        document.querySelector('body').setAttribute('style', 'background-color:#F7F7F7')

    },
    beforeDestroy() {
        document.querySelector('body').setAttribute('style', '')
    },
    mounted() {},
    methods: {
        // 分页
        pageSearch(pager) {
            this.pagination.pageNo = pager.pageNo;
            this.pagination.pageSize = pager.pageSize;
            this.page.pageNo = pager.pageNo;
            this.page.pageSize = pager.pageSize
            this.pageLoadFlag = true;
            this.getCommodityList();
        },
        inputSearch(data) {
            this.paramList = data[1].paramList;
            this.paramListExport = data[0];
            this.searchzz = true;
            this.pageLoadFlag = true;
            this.getCommodityList();
        },
        // 导出
        exportClick() {
            let data = JSON.parse(JSON.stringify(this.requestdata));
            data.page.pageSize = 300000;
            data.page.pageNo = 1;
            this.isLoading = true;
            exportDate(data).then(res => {
                Util.blobToJson(res.data).then(content => {
                    this.isLoading = false;
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '零售订单.xls')
                }).finally(() => {
                    this.isLoading = false;
                })
            })
        },
        // 全选
        chooseAll(e) {
            if (e.target.checked) {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = true;
                }
            } else {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            }
            this.$forceUpdate();
        },
        //批量失效
        invalidClick() {
            let newarraySend = this.invalidList.join(',')
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                userAccount: this.$store.state.user.userInfo.customer.userAccount,
                salesOrderIds: newarraySend
            }
            batch(data).then(res => {
                var realData = JSON.parse(res.data);
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                } else if (realData.code == '0') {
                    this.$message.success(realData.msg)
                }
            })
        },

        // 商品选择
        chooseItem(data, index, e) {
            this.goodsList[index].checked = e.target.checked;
            var pushY = this.invalidList.indexOf(data);
            if (pushY == -1) {
                this.invalidList.push(data);
            } else {
                this.invalidList.splice(pushY, 1);
            }
            this.$forceUpdate()
        },
        // 点击审核
        shenhe(id) {
            this.$router.push({
                name: 'rderExamine',
                query: {
                    id
                }
            })
        },
        //查看订单详情
        toDetail(type, id) {
            debugger
            // // normal 渠道   reatail 零售
            if (type == "retail") {
                let routeUrl = this.$router.resolve({
                    path: '/retailOrder/detail',
                    query: {
                        id
                    }
                });
                window.open(routeUrl.href, "_blank");
            } else {
                let routeUrl = this.$router.resolve({
                    path: '/channelOrder/detail',
                    query: {
                        id
                    }
                });
                window.open(routeUrl.href, "_blank");
            }
        },
        // 查看订单进度
        toProgress(type, id) {
            if (type == "retail") {
                let routeUrl = this.$router.resolve({
                    path: '/retailOrder/progress',
                    query: {
                        id
                    }
                });
                window.open(routeUrl.href, "_blank");

            } else {
                let routeUrl = this.$router.resolve({
                    path: '/channelOrder/progress',
                    query: {
                        id
                    }
                });
                window.open(routeUrl.href, "_blank");
            }
        },
        // 点击出库
        chuku(id) {
            this.$router.push({
                name: "wharehouse",
                query: {
                    id
                }
            })
        },
        // 商品列表
        getCommodityList() {
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                terms: {
                    endDate: '',
                    documentType: this.$route.query.type,
                    documentNum: '',
                    sellerName: '',
                    returnStatus: '',
                    purchaseNum: '',
                    customerName: '',
                    startDate: '',
                    status: '',
                    paramList: this.paramList
                },
                page: this.page
            }
            this.requestdata = data;
            getList(data).then(res => {
                var realDate = JSON.parse(res.data);
                this.tableData = realDate.data;
                this.tableData.forEach(it => {
                    if(it.isSpeclalShop == 'T') {
                      it.isSpeclalShop ='是'
                    }
                    if(it.isSpeclalShop == 'F') {
                      it.isSpeclalShop ='否'
                    }
                  })
                this.loadings = false;
                this.pageLoadFlag = false;
                this.goodsListlength = realDate.page.totalCount;
                this.pagination.count = realDate.page.totalCount;
            })
        },
        // 加载更多
        loadMore() {
            this.loadings = true;
            this.page.pageNo++;
            this.getCommodityList();
        }
    }
}